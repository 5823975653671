<template>
  <div>
    <div class="header">
      <van-nav-bar title="收益" left-arrow @click-left="$router.go(-1)" />
    </div>
    <div class="maincontent">
      <div class="income-content">
        <div class="item">
          <div class="item-title">日收益</div>
          <div class="item-price">{{ dailyProfit | priceformat }}</div>
        </div>
        <div class="item">
          <div class="item-title">周收益</div>
          <div class="item-price">{{ weeklyProfit | priceformat }}</div>
        </div>
        <div class="item">
          <div class="item-title">月收益</div>
          <div class="item-price">{{ monthlyProfit | priceformat }}</div>
        </div>
        <div class="item">
          <div class="item-title">总收益</div>
          <div class="item-price">{{ income | priceformat }}</div>
        </div>
      </div>
      <div class="chart-box">
        <div class="chart-top">
          <div>收益</div>
          <div @click="show = true">
            {{ date }}
            <van-icon name="arrow-down" />
          </div>
        </div>
        <div class="chart">
          <qiun-vue-ucharts type="line" :opts="opts" :chartData="chartData" />
        </div>
      </div>
    </div>
    <van-calendar
      v-model="show"
      type="range"
      :minDate="minDate"
      color="#0055ff"
      @confirm="onConfirm"
      :default-date="defaultDate"
    />
  </div>
</template>
<script>
import qiunVueUcharts from '@qiun/vue-ucharts'
import dayjs from 'dayjs'
export default {
  components: {
    qiunVueUcharts,
  },
  data() {
    return {
      show: false,
      minDate: new Date(2024, 1, 1),
      date: '',
      defaultDate: [],
      chartData: {},
      //您可以通过修改 config-ucharts.js 文件中下标为 ['line'] 的节点来配置全局默认参数，如都是默认参数，此处可以不传 opts 。实际应用过程中 opts 只需传入与全局默认参数中不一致的【某一个属性】即可实现同类型的图表显示不同的样式，达到页面简洁的需求。
      opts: {
        color: [
          '#4B98FE',
          '#00D05E',
          '#FFAC00',
          '#FB6A67',
          '#957BFE',
          '#00B9FE',
          '#FE871B',
          '#00C8B0',
          '#F674D6',
        ],
        padding: [15, 0, 0, 0],
        dataLabel: false,
        xAxis: {
          // disabled: true,
          // axisLabel: {
          //   interval: 1, // 或者设置为其他数字
          // },
          formatter: function (value) {
            return ''
          },
        },
        yAxis: {
          gridColor: 'rgba(230,230,230,0.6)',
          gridType: 'dash',
          dashLength: '4',
          data: [
            {
              axisLineColor: '#FFFFFF',
            },
          ],
        },
        legend: {
          show: false,
          position: 'top',
          float: 'right',
        },
        extra: {
          area: {
            type: 'curve',
            opacity: 0.8,
            addLine: true,
            width: 1,
            gradient: true,
          },
        },
        startDate: '',
        endData: '',
      },
      income: '0.00',
      dailyProfit: '0.00',
      weeklyProfit: '0.00',
      monthlyProfit: '0.00',
    }
  },
  created() {
    const now = dayjs()
    // 获取近一个月的起始日期
    const startOfLastMonth = now.subtract(1, 'month').startOf('day')
    console.log(startOfLastMonth.format('YYYY-MM-DD')) // 输出格式化的日期
    this.startDate = startOfLastMonth.format('YYYY/MM/DD')
    this.endDate = now.format('YYYY/MM/DD')
    this.date = this.startDate + '-' + this.endDate
    this.defaultDate = [startOfLastMonth.toDate(), now.toDate()]
    this.getIncomes()
    this.getCounts()
    this.getIncome()
  },
  methods: {
    // 总收益
    async getIncome() {
      const { data } = await this.$http.get('/home/trade/get_income')
      if (data) {
        if (data.code === 200) {
          this.income = data.data.total_income
        }
      }
    },
    async getIncomes() {
      let start = dayjs(
        dayjs(this.startDate).format('YYYY-MM-DD 00:00:00')
      ).unix()
      let end = dayjs(dayjs(this.endDate).format('YYYY-MM-DD 59:59:59')).unix()
      const postform = { start, end }
      const { data } = await this.$http.post(
        '/home/trade/getOrderIncome',
        postform
      )
      if (data) {
        if (data.code === 200) {
          let res = {
            categories: [],
            series: [
              {
                name: '收益',
                data: [],
                legendShape: 'circle',
                color: '#4088FE',
                pointShape: 'none',
              },
            ],
          }
          if (data.data.length > 0) {
            data.data.map((item) => {
              res.categories.push(
                dayjs(dayjs.unix(item.createtime)).format('MM-DD HH:mm')
              )
              res.series[0].data.push(Number(item.profit).toFixed(2))
            })
            this.chartData = JSON.parse(JSON.stringify(res))
          } else {
            res.categories = []
            res.series[0].data = []
            this.chartData = JSON.parse(JSON.stringify(res))
          }
        }
      }
    },
    async getCounts() {
      const { data } = await this.$http.get('/home/trade/getIncomeCount')
      if (data) {
        if (data.code === 200) {
          this.dailyProfit = data.data.dailyProfit || '0.00'
          this.weeklyProfit = data.data.weeklyProfit || '0.00'
          this.monthlyProfit = data.data.monthlyProfit || '0.00'
        }
      }
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    },
    onConfirm(date) {
      const [start, end] = date
      this.show = false
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`
      this.startDate = dayjs(start).format('YYYY/MM/DD')
      this.endDate = dayjs(end).format('YYYY/MM/DD')
      this.getIncomes()
    },
  },
}
</script>
<style lang="less" scoped>
.maincontent {
  padding: 65px 1rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background-image: linear-gradient(
    125deg,
    #cb9ffe,
    #5894f7,
    #abcdfa,
    #74a3f6,
    #cb9ffe
  );
  height: 100vh;
  .van-cell__title {
    text-align: left;
    font-size: 0.93333rem;
  }
  .van-cell {
    font-size: 0.98667rem;
    background-color: #fff !important;
    height: 3.46667rem;

    &:after {
      border-bottom: 0.02667rem solid #d0d0d1;
    }
  }
  .income-content {
    display: flex;
    justify-content: space-between;
    padding: 15px 5px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    .item {
      flex: 1;
      .item-title {
        color: #999;
      }
      .item-price {
        margin-top: 5px;
        font-size: 16px;
        color: red;
      }
    }
  }
  .chart-box {
    margin-top: 1rem;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    .chart-top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      font-size: 13px;
      .top-date {
        display: flex;
      }
    }
    .chart {
      height: 13rem;
    }
  }
}
</style>
